import PropTypes from "prop-types";
import React from "react";

const InlineError = ({ msg, ...props }) => {
  return (
    <p className="inline-errors" {...props}>
      <span dangerouslySetInnerHTML={{ __html: msg }} />
    </p>
  );
};

InlineError.propTypes = {
  msg: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};

export default InlineError;
