import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import SidePanelWrapper from "./SidePanelWrapper";

const ImageSidePanel = ({
  backgroundColor,
  badgeColor,
  badgeTextColor,
  image,
  imageAlt,
  job,
  name,
  subTitle,
  title
}) => {
  const { t } = useTranslation();

  return (
    <SidePanelWrapper backgroundColor={backgroundColor}>
      {subTitle ? (
        <div className="use-case">
          <h2>{t(title)}</h2>
          <p>{t(subTitle)}</p>
          <figure>
            <img src={image} alt={t(imageAlt)} className="w-100" />
            <figcaption>{t(imageAlt)}</figcaption>
          </figure>
        </div>
      ) : (
        <>
          <div
            className="image-holder"
            style={{
              backgroundImage: `url('${image}')`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat"
            }}
          />
          <p className="h3 mt-3 text-white image-copy">{t(title)}</p>
        </>
      )}
      {name ? (
        <div
          className="image-label"
          style={{ backgroundColor: badgeColor, color: badgeTextColor }}
        >
          <strong className="image-label__title">{name}</strong>
          <span className="image-label__subtitle">{t(job)}</span>
        </div>
      ) : null}
    </SidePanelWrapper>
  );
};

ImageSidePanel.propTypes = {
  backgroundColor: PropTypes.string,
  badgeColor: PropTypes.string,
  badgeTextColor: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  job: PropTypes.string,
  name: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string
};

export default ImageSidePanel;
