import flowReducer from "./flowReducer";
import imageSidePanelReducer from "./imageSidePanelReducer";
import nbuildReducer from "./nbuildReducer";
import networkReducer from "./networkReducer";
import pricingInfoReducer from "./pricingInfoReducer";
import userDataReducer from "./userDataReducer";

const mainReducer = (
  { flow, imageSidePanel, nbuild, network, pricingInfo, userData },
  action
) => ({
  flow: flowReducer(flow, action),
  imageSidePanel: imageSidePanelReducer(imageSidePanel, action),
  nbuild: nbuildReducer(nbuild, action),
  network: networkReducer(network, action),
  pricingInfo: pricingInfoReducer(pricingInfo, action),
  userData: userDataReducer(userData, action)
});

export default mainReducer;
