import cn from "classnames";
import PropTypes from "prop-types";
import React from "react";

const FormGroup = ({ error, classes, warning, children }) => {
  const classNames = cn("form-group", "col", classes, {
    error: error || warning,
    "error--warning": warning && !error
  });

  return <div className={classNames}>{children}</div>;
};

FormGroup.defaultProps = {
  classes: "",
  error: false,
  warning: false
};

FormGroup.propTypes = {
  classes: PropTypes.string,
  error: PropTypes.bool,
  warning: PropTypes.bool
};

export default FormGroup;
