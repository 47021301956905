import PropTypes from "prop-types";
import React from "react";

const Button = ({
  type,
  disabled,
  classes,
  ariaLabel,
  handleClick,
  handleMouseDown,
  children,
  ...rest
}) => (
  <button
    type={type}
    className={"btn " + classes}
    disabled={disabled}
    onClick={handleClick}
    onMouseDown={handleMouseDown}
    aria-label={ariaLabel}
    {...rest}
  >
    {children}
  </button>
);

Button.defaultProps = {
  type: "button",
  disabled: false,
  classes: "btn-positive",
  ariaLabel: "",
  handleClick: undefined,
  handleMouseDown: undefined
};

Button.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
  ariaLabel: PropTypes.string,
  handleClick: PropTypes.func,
  handleMouseDown: PropTypes.func
};

export default Button;
