import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { defaultAll } from "../../lib/utils";
// import { DisplayFormikState } from "../../helpers";
import ButtonGroup from "../ButtonGroup";
import FormRow from "../FormRow";
import StaticSidePanel from "../side-panels/StaticSidePanel";
import { getTrialPricingInfo } from "../../api";
import { useStateValue } from "../../hooks/state";
import { useTranslation } from "react-i18next";

const Language = ({ fullWidth, saveStep, testId, title }) => {
  const [{ userData: { language } = {}, nbuild }, dispatch] = useStateValue();
  const { i18n, t } = useTranslation();

  // Only re-run the effect if language changes
  useEffect(() => {
    if (language) {
      getTrialPricingInfo(language, nbuild, dispatch);
    }
  }, [language]);

  return (
    <main
      data-testid={testId}
      className="row bg-white position-absolute w-100 min-vh-100"
    >
      <div className="col-12 col-md align-self-center">
        <div
          className="mx-auto px-3 py-5"
          style={{ maxWidth: fullWidth ? "490px" : "560px" }}
        >
          <h1 className="mt-4 mt-md-5">{title}</h1>
          <Formik
            initialValues={defaultAll({ language })}
            onSubmit={values => {
              i18n.changeLanguage(values.language);
              saveStep(values);
            }}
          >
            {props => (
              <Form>
                <fieldset>
                  <legend className="sr-only">{t('components.steps.language.choose_language')}</legend>
                  <FormRow>
                    <ButtonGroup
                      name="language"
                      options={{
                        en: "English (US)",
                        "en-GB": "English (GB)",
                        "fr-CA": "Français (CA)",
                        "fr-FR": "Français (FR)",
                        es: "Español"
                      }}
                      selectedValue={language}
                      {...props}
                    />
                  </FormRow>
                </fieldset>
                {/* <DisplayFormikState {...props} /> */}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {fullWidth ? null : <StaticSidePanel />}
    </main>
  );
};

Language.propTypes = {
  fullWidth: PropTypes.bool,
  saveStep: PropTypes.func.isRequired
};

export default Language;
