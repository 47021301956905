import Account from "./Account";
import Billing from "./Billing";
import Country from "./Country";
import Language from "./Language";
import Settings from "./Settings";
import UseCase from "./UseCase";

const steps = {
  settings: {
    description: 'components.steps.settings.take_a_moment_to_enter',
    component: Settings,
    fullWidth: false,
    name: "settings"
  },

  account: {
    description: 'components.steps.account.lets_secure_your_basic',
    component: Account,
    fullWidth: false,
    name: "account"
  },

  billing: {
    description: 'components.steps.billing.finally_enter_your_billing',
    component: Billing,
    fullWidth: false,
    name: "billing"
  },

  country: {
    description: 'components.steps.country.what_country_are_you_based_in',
    component: Country,
    fullWidth: true,
    name: "country"
  },

  language: {
    description: 'components.steps.language.welcome_lets_get_started',
    component: Language,
    fullWidth: true,
    name: "language"
  },

  useCase: {
    description: 'components.steps.use_case.lets_set_up_your_new_nation',
    component: UseCase,
    fullWidth: false,
    name: "useCase"
  }
};

export default steps;
