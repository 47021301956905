import React from "react";

const Note = props => (
  <div className={`note mt-2 ${props.animated ? 'note--animated' : ''}`}>
    <div className="media">
      <div className="align-self-center mr-2">
        <svg
          focusable="false"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            fill="#1498BE"
            fillRule="nonzero"
            d="M9 0C4.02948 0 0 4.02952 0 9.00009 0 13.97048 4.02948 18 9 18s9-4.02952 9-8.99991C18 4.02952 13.97052 0 9 0zm.32144 4.33763C9.54096 4.11377 9.80851 4 10.11623 4c.2495 0 .46903.08432.63387.24392.16582.1587.2499.36175.2499.60291 0 .30229-.1125.57106-.33418.79879C10.43806 5.88052 10.1717 6 9.87495 6c-.245 0-.46197-.08634-.6274-.24963C9.08369 5.59039 9 5.38126 9 5.12979c0-.308.1082-.57456.32144-.79216zm1.59954 8.09781c-.74596.66333-1.27848 1.07685-1.62725 1.26472-.37217.20148-.67884.29984-.93709.29984-.25663 0-.46593-.08083-.6225-.23994-.15171-.15384-.22856-.36162-.22856-.61687 0-.65244.39089-2.16765 1.19552-4.63224.01368-.04169.0243-.08134.0315-.11827-.03006.01225-.0648.02961-.1051.05327-.06588.0393-.26654.1882-.85377.73071-.08962.08288-.22981.09053-.32898.01787l-.34733-.25407c-.05543-.04033-.09034-.1009-.09646-.16693-.00612-.06586.01727-.1312.06425-.18022.54115-.56343 1.04632-.97252 1.50163-1.21587C9.0434 7.12354 9.44634 7 9.79817 7c.22748 0 .41122.05343.54656.15928.14721.11606.22496.27721.22496.46627 0 .12405-.06227.44721-.5219 1.90473-.65652 2.08256-.81345 2.83846-.82407 3.10001.12418-.06943.41806-.26683 1.04542-.82431.09773-.08747.25106-.08577.34698.00272l.30792.28385c.04913.04526.07667.10738.07596.17221-.00073.06484-.02917.12627-.07902.17068z"
          />
        </svg>
      </div>
      <div className="media__body">{props.notes || props.children}</div>
    </div>
  </div>
);

export default Note;
