const imageSidePanelReducer = (state, action) => {
  const { type, ...rest } = action;
  switch (type) {
    case "set-side-panel":
      return { ...rest };
    default:
      return state;
  }
};

export default imageSidePanelReducer;
