import PropTypes from "prop-types";
import React from "react";
import { mapEach } from "../lib/utils";

import FormGroup from "./FormGroup";
import ButtonGroupInput from "./inputs/ButtonGroupInput";

const ButtonGroup = ({
  cols = 3,
  disabled,
  onMouseEnter,
  onMouseLeave,
  isSubmitting,
  name,
  options,
  selectedValue,
  setFieldValue
}) => {
  const handleClick = val => setFieldValue(name, val);

  return (
    <FormGroup classes={`btn-radio-col-${cols}`}>
      {mapEach(options, (value, label) => (
        <div className="w-100" key={value}>
          <ButtonGroupInput
            active={value === selectedValue}
            disabled={isSubmitting}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            label={label}
            onClick={() => handleClick(value)}
            value={value}
          />
        </div>
      ))}
    </FormGroup>
  );
};

ButtonGroup.propTypes = {
  cols: PropTypes.number,
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.object,
  selectedValue: PropTypes.string
};

export default ButtonGroup;
