import React, { createContext, useContext } from "react";

export const StateContext = createContext();

export const StateProvider = ({ state, dispatch, children }) => (
  <StateContext.Provider value={[state, dispatch]}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);
export const useStateDispatch = () => useStateValue()[1];
