import React from "react";
import { useTranslation } from "react-i18next";

const ApiError = () => {
  const { t } = useTranslation();

  return (
    <div className="row bg-white position-absolute w-100 h-100 justify-content-center align-items-center">
      <div style={{ maxWidth: "700px" }}>
        <p>
          <img
            alt="NationBuilder"
            src="/images/nationbuilder-horizontal-black.svg"
            width="300"
          />
        </p>
        <h1 className="mt-4 mt-md-5">{t("errors.service_down.header")}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: t("errors.service_down.body", {
              url: window.location.href,
            }),
          }}
        />
      </div>
    </div>
  );
};

export default ApiError;
