import "./lib/polyfills.js"
import ErrorBoundary from "@honeybadger-io/react";
import React from "react";
import ReactDOM from "react-dom";

import App, { initialState } from "./App";
import honeybadger from "./lib/honeybadger";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <ErrorBoundary honeybadger={honeybadger}>
    <App initialState={initialState} />
  </ErrorBoundary>,
  rootElement
);
