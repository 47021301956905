import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translations from "./translations";

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  debug: false,

  resources: translations,

  react: {
    useSuspense: false
  },

  interpolation: {
    prefix: "%{",
    suffix: "}",
    escapeValue: false // not needed for react as it escapes by default
  },

  parseMissingKeyHandler: key => {
    const matches = key.match(/\.(\w*)$/);
    const ret = matches && matches.length > 1 ? matches[1] : key;
    return ret.replace(/_/g, " ");
  }
});

export default i18n;
