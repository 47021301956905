import { useEffect } from "react";
import { getInitialNbuildData } from "../api";

const useNbuildData = (sid, callback) => {
  useEffect(() => {
    getInitialNbuildData(sid, callback)
      .then(data => {
        callback(data);
      })
      .catch(e => callback(null));
  }, []);
};

export default useNbuildData;
