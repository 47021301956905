import React from "react";
import { useTranslation } from "react-i18next";

import { useStateValue } from "../hooks/state";

const successCheckmark = () => {
  return (
    <span className="col-auto pr-0 text-success">
      <svg
        width="1.5em"
        height="1.5em"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        aria-hidden="true"
      >
        <path
          fill="currentColor"
          d="M19.313 5.892L9.188 16.017l-4.105-4.1-.396-.4-.795.796.4.395 4.896 4.9 10.92-10.92z"
        />
      </svg>
    </span>
  );
};

const yourPromotionSection = (promotion) => {
  if (promotion) {
    return (
      <div data-testid="pricing-promotion">
        <h2 className="h3">{promotion.section_header}</h2>
        <span className="font-weight-bold">{promotion.title}</span>
        <p
          dangerouslySetInnerHTML={{
            __html: promotion.description
          }}
        />
      </div>
    );
  }

  return null;
};

const Pricing = () => {
  const { t } = useTranslation();
  const [{ pricingInfo: { trial_pricing_text, change_or_cancel_text, promotion } = {} }] = useStateValue();

  return (
    <div>
      <div className="row justify-content-between mb-2">
        <div className="col-12 col-lg-6">
          <h2 className="h3">{t("components.side_panel.pricing.no_setup_fees")}</h2>
          <ul className="list-unstyled">
            <li className="row">
              {successCheckmark()}
              <span className="col pl-2">{t("components.side_panel.pricing.support_7_days_a_week")}</span>
            </li>
            <li className="row">
              {successCheckmark()}
              <span className="col pl-2">{t("components.side_panel.pricing.free_online_training_sessions")}</span>
            </li>
          </ul>
        </div>
        <div className="col-12 col-lg-6">
          <h2 className="h3">{t("components.side_panel.pricing.you_own_your_data")}</h2>
          <ul className="list-unstyled">
            <li className="row">
              {successCheckmark()}
              <span className="col pl-2">{t("components.side_panel.pricing.private_and_secure")}</span>
            </li>
            <li className="row">
              {successCheckmark()}
              <span className="col pl-2">{t("components.side_panel.pricing.import_and_export_anytime")}</span>
            </li>
          </ul>
        </div>
      </div>
      {yourPromotionSection(promotion)}
      <div>
        <h2 className="h3">{t("components.side_panel.pricing.14_day_free_trial")}</h2>
        <p
          data-testid="pricing-14-day-trial"
          dangerouslySetInnerHTML={{
            __html: trial_pricing_text
              ? trial_pricing_text
              : t("components.side_panel.pricing.14_day_free_trial_error")
          }}
        />
        <h2 className="h3">{t("components.side_panel.change_or_cancel")}</h2>
        <p
          data-testid="pricing-change-or-cancel"
          dangerouslySetInnerHTML={{
            __html: change_or_cancel_text ? change_or_cancel_text : t("components.side_panel.change_or_cancel_error")
          }}
        />
      </div>
      <p
        className="h3"
        dangerouslySetInnerHTML={{
          __html: t("components.side_panel.questions")
        }}
      />
    </div>
  );
};

export default Pricing;
