import PropTypes from "prop-types";
import React from "react";

const TextInput = ({ type = "text", field, ...props }) => (
  <input
    type={type}
    className="form-control"
    id={field.name}
    data-testid={`${field.name}-input`}
    maxLength="255"
    {...field}
    {...props}
  />
);

TextInput.propTypes = {
  field: PropTypes.object
};

export default TextInput;
