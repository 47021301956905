import React from "react";
import { useTranslation } from "react-i18next";

import Button from "./Button";
import { useFlow } from "../hooks/flow";

const BackButton = () => {
  const { isFirstStep, gotoPrevStep } = useFlow();
  const { t } = useTranslation();

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      gotoPrevStep();
    }
  };

  return isFirstStep ? null : (
    <Button
      classes="btn-back"
      ariaLabel={t("components.back_button.back")}
      handleMouseDown={gotoPrevStep}
      onKeyDown={handleKeyDown}
      data-testid="back-button"
    >
      <span aria-hidden="true">
        <svg
          width="10"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 1.32959c0 .13886-.0574.26185-.17221.36898L3.07581 7.9991l6.75198 6.3005c.11481.1072.17221.2302.17221.369 0 .1389-.0574.2619-.17221.369l-.85915.8017c-.11481.1071-.24662.1607-.39542.1607-.14881 0-.28062-.0536-.39543-.1607L.172215 8.36898C.0574049 8.26185 0 8.13885 0 8c0-.13885.0574049-.26185.172215-.36898L8.17779.1607C8.2926.0535668 8.42441 0 8.57322 0c.1488 0 .28061.0535668.39542.1607l.85915.801706C9.9426 1.06954 10 1.19253 10 1.33139v-.0018z"
            fill="#1498BE"
          />
        </svg>
      </span>
    </Button>
  );
};

export default BackButton;
