import PropTypes from "prop-types";
import React from "react";

const FormRow = ({ classes, children }) => (
  <div className={`form-row ${classes}`}>{children}</div>
);

FormRow.defaultProps = {
  classes: ""
};

FormRow.propTypes = {
  classes: PropTypes.string
};

export default FormRow;
