import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

// import { DisplayFormikState } from "../../helpers";
import ButtonGroup from "../ButtonGroup";
import ConnectedImageSidePanel from "../side-panels/ConnectedImageSidePanel";
import FormRow from "../FormRow";
import { useStateDispatch } from "../../hooks/state";

const DEFAULT_SIDE_IMAGE = "other";

const UseCase = ({ fullWidth, saveStep, testId, title }) => {
  const dispatch = useStateDispatch();
  const { t } = useTranslation();
  let leaveTimeoutId = null;

  const handleMouseEnter = selection => {
    clearInterval(leaveTimeoutId);
    dispatch({
      type: "set-side-panel",
      image: selection
    });
  };

  const containerStyle = {
    height: '100vh',
    display: 'flex'
  };

  const handleMouseLeave = () => {
    leaveTimeoutId = setTimeout(() => {
      dispatch({
        type: "set-side-panel",
        image: DEFAULT_SIDE_IMAGE
      });
    }, 1000);
  };

  return (
    <div
      data-testid={testId}
      className="row bg-white position-absolute w-100 min-vh-100"
    >
      <main style={containerStyle} className="col-md-6">
        <div className="col-12 col-md-12 align-self-center ">
          <div
            className="mx-auto px-3 py-5"
            style={{ maxWidth: fullWidth ? "490px" : "560px" }}
          >
            <h1 className="mt-4 mt-md-5">{title}</h1>
            <Formik onSubmit={values => saveStep(values)}>
              {props => (
                <Form>
                  <fieldset>
                    <legend className="sr-only">{t("components.steps.use_case.choose_use_case")}</legend>
                    <FormRow>
                      <ButtonGroup
                        cols={2}
                        name="useCase"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        options={{
                          advocacy: t("components.steps.use_case.advocacy"),
                          campaign: t("components.steps.use_case.campaign"),
                          community: t("components.steps.use_case.community"),
                          nonprofit: t("components.steps.use_case.nonprofit"),
                          party: t("components.steps.use_case.party"),
                          chapter: t("components.steps.use_case.chapter"),
                          union: t("components.steps.use_case.union"),
                          other: t("components.steps.use_case.other")
                        }}
                        {...props}
                      />
                    </FormRow>
                  </fieldset>
                  {/* <DisplayFormikState {...props} /> */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </main>

      {fullWidth ? null : (
        <ConnectedImageSidePanel
          category="useCase"
          initialImage={DEFAULT_SIDE_IMAGE}
        />
      )}
    </div>
  );
};

UseCase.propTypes = {
  fullWidth: PropTypes.bool,
  saveStep: PropTypes.func.isRequired
};

export default UseCase;
