import {
  CardElement,
  Elements,
  injectStripe,
  StripeProvider
} from "react-stripe-elements";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import InlineError from "../InlineError";
import FormGroup from "../FormGroup";
import FormRow from "../FormRow";
import Pricing from "../Pricing";
import StaticSidePanel from "../side-panels/StaticSidePanel";
import SubmitButton from "../SubmitButton";
import { useStateValue } from "../../hooks/state";

const BillingForm = ({
  fullWidth,
  networkErrors,
  saveStep,
  scaSecret,
  stripe,
  testId,
  title
}) => {
  const [{ userData: { firstName } = {} }] = useStateValue();
  const [error, setError] = useState(networkErrors && networkErrors.stripe);
  const [cardElement, setCardElement] = useState("");
  const [acceptTOS, setAcceptTOS] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const { i18n, t } = useTranslation();

  const cardStyle = {
    base: {
      color: "#000",
      fontFamily: "aktiv-grotesk, Helvetica Neue, Helvetica, Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "15px",
      "::placeholder": {
        color: "#999"
      }
    },
    invalid: {
      color: "#d0021b",
      iconColor: "#fa755a"
    }
  };

  const handleCardChange = ({ error }) => {
    error ? setError(error.message) : setError("");
  };

  const handleTOSChange = e => {
    setAcceptTOS(e.target.checked);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!acceptTOS) {
      return;
    }

    setSubmitting(true);
    const { setupIntent, error } = await stripe.handleCardSetup(
      scaSecret,
      cardElement
    );
    if (error) {
      setError(error.message);
      setSubmitting(false);
    } else {
      saveStep({
        stripePaymentMethod: setupIntent.payment_method,
        stripeClientId: setupIntent.id,
        acceptTOS
      });
    }
  };

  const handleCardReady = element => {
    setCardElement(element);
  };

  useEffect(() => {
    window.intercomSettings.hide_default_launcher = false;
    window.intercomSettings.language_override = i18n.language;
    window.Intercom("boot", {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      name: firstName,
      custom_launcher_selector: "#intercom_launcher"
    });
  }, []);

  return (
    <div
      data-testid={testId}
      className="row bg-white position-absolute w-100 min-vh-100"
      style={{ overflowY: "auto", WebkitOverflowScrolling: "touch" }}
    >
      <main className="col-12 col-md-6 align-self-center ">
        <div
          className="mx-auto px-3 py-5"
          style={{ maxWidth: fullWidth ? "490px" : "560px" }}
        >
          <h1 className="mt-4 mt-md-5">{title}</h1>

          <form id="form" onSubmit={handleSubmit}>
            <FormRow>
              <FormGroup classes={`form-input ${error ? "error" : ""}`}>
                <div className="form-field-label">
                  <label className="" htmlFor="card-element">
                    {t("forms.labels.credit_or_debit_card_information")}
                  </label>
                </div>
                <div className="form-field-wrap">
                  <div className="form-field-wrap__item">
                    <CardElement
                      onChange={handleCardChange}
                      style={cardStyle}
                      onReady={handleCardReady}
                    />
                    {error && <InlineError msg={error} />}
                  </div>
                </div>
              </FormGroup>
            </FormRow>

            <FormRow>
              <FormGroup classes="form-checkbox">
                <div className="form-field-wrap">
                  <div className="form-field-wrap__item">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      name="agreeToTerms"
                      id="agreeToTerms"
                      data-testid="tos-input"
                      value="1"
                      onChange={handleTOSChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="agreeToTerms"
                      dangerouslySetInnerHTML={{
                        __html: t("forms.labels.terms_of_service")
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
            </FormRow>

            <SubmitButton disabled={!!error || !acceptTOS || isSubmitting} />
          </form>
        </div>
      </main>
      {fullWidth ? null : (
        <StaticSidePanel backgroundColor="#E7F4F8" showOnMobile>
          <Pricing />
        </StaticSidePanel>
      )}
    </div>
  );
};

const StripeBillingForm = injectStripe(BillingForm);

const Billing = ({ saveStep, networkErrors, title }) => {
  const { i18n } = useTranslation();
  const [stripe, setStripe] = useState(null);
  const [{ nbuild: { sca_secret } = {} }] = useStateValue();

  useEffect(() => {
    if (window.Stripe) {
      setStripe(
        window.Stripe(process.env.REACT_APP_STRIPE_API_KEY, {
          locale: i18n.language
        })
      );
    }
  }, []);

  return (
    <StripeProvider stripe={stripe}>
      <Elements>
        <StripeBillingForm
          saveStep={saveStep}
          networkErrors={networkErrors}
          scaSecret={sca_secret}
          title={title}
        />
      </Elements>
    </StripeProvider>
  );
};

Billing.propTypes = {
  fullWidth: PropTypes.bool,
  saveStep: PropTypes.func.isRequired
};

export default Billing;
