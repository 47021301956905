import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import SubmissionSpinner from "../SubmissionSpinner";
import ApiError from "../ApiError";
import { useFlow } from "../../hooks/flow";
import { useStateValue } from "../../hooks/state";

const StepManager = ({ onFinish, networkErrors, serviceDown }) => {
  const [state, dispatch] = useStateValue();
  const { t } = useTranslation();
  const { currentStep, currentStepIndex, gotoNextStep, finished } = useFlow();

  const showSpinner = finished;

  useEffect(() => {
    const path = finished ? "thankyou" : currentStep.name;
    const cookieConsent =
      window.Cookiebot &&
      window.Cookiebot.consent &&
      window.Cookiebot.consent.statistics;
    if (window.ga && cookieConsent) {
      window.ga("set", "page", `/steps/${path}`);
      window.ga("send", "pageview");
    }
  }, [currentStep.name, finished]);

  useEffect(() => {
    if (finished) {
      onFinish(state, dispatch);
    }
  }, [finished]);

  const saveStep = data => {
    if (data) {
      if (data.country && data.country !== "US") {
        data.state = undefined;
        data.zip = undefined;
        data.taxExempt = false;
      }

      dispatch({ type: "set-data", data });
    }

    gotoNextStep();
  };

  return state.network.serviceDown ? (
    <ApiError />
  ) : showSpinner ? (
    <SubmissionSpinner
      showSpinner={state.network.saving}
      createStatus={state.network.createStatus}
    />
  ) : (
    <currentStep.component
      fullWidth={currentStep.fullWidth}
      networkErrors={networkErrors}
      saveStep={saveStep}
      testId={`step-${currentStepIndex + 1}-${currentStep.name}`}
      title={t(currentStep.description)}
    />
  );
};

StepManager.propTypes = {
  networkErrors: PropTypes.object,
  onFinish: PropTypes.func.isRequired
};

export default StepManager;
