import examples from "libphonenumber-js/examples.mobile.json";
import {
  formatIncompletePhoneNumber,
  getExampleNumber,
} from "libphonenumber-js";
import PropTypes from "prop-types";
import React from "react";

const PhoneInput = ({
  field,
  setFieldValue,
  countryCode,
  country,
  ...props
}) => {
  return (
    <input
      type="text"
      className="form-control"
      id={field.name}
      data-testid={`${field.name}-input`}
      maxLength="255"
      placeholder={phonePlaceholder(country)}
      onKeyUp={(value) => {
        if (field.value.replace(/[^0-9]/g, "").length > 4)
          setFieldValue(
            field.name,
            formatIncompletePhoneNumber(countryCode + field.value, country)
          );
      }}
      {...field}
      {...props}
    />
  );
};

const phonePlaceholder = (country) => {
  let example = getExampleNumber(country, examples);
  if (typeof example !== "undefined") {
    return example.formatNational();
  } else {
    return "";
  }
};

PhoneInput.propTypes = {
  country: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object,
};

PhoneInput.defaultProps = {
  disabled: false,
  country: "US",
};

export default PhoneInput;
