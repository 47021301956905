import React from "react";
import { useTranslation } from "react-i18next";
import { CREATE_STATUS } from "../api";

const SubmissionSpinner = ({ showSpinner, createStatus }) => {
  const { t } = useTranslation();

  let statusMessage = null;
  switch (createStatus) {
    case CREATE_STATUS.TIMEOUT_WARNING:
      statusMessage = t("components.submission.thank_you_for_your_patience");
      break;
    case CREATE_STATUS.TIMEOUT:
      statusMessage = t(
        "components.submission.setup_is_taking_longer_than_expected"
      );
      break;
    default:
      statusMessage = t("components.submission.setting_up_your_nation");
  }

  return (
    <div
      className="row bg-white position-absolute w-100 h-100 justify-content-center align-items-center"
      style={{ overflow: "scroll" }}
    >
      <div>
        {showSpinner ? (
          <div className="p-3">
            <div className="spinner">
              <svg viewBox="0 0 100 100" preserveAspectRatio="none">
                <circle
                  cx="50"
                  cy="50"
                  r="20"
                  stroke="currentColor"
                  fill="transparent"
                />
              </svg>
            </div>
          </div>
        ) : null}

        <p className="font-weight-bold">{statusMessage}</p>
      </div>
    </div>
  );
};

export default SubmissionSpinner;
