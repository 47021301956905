import PropTypes from "prop-types";
import React from "react";

const CheckboxInput = ({ type = "checkbox", field, ...props }) => {
  return (
    <>
      <input
        type={type}
        className="form-check-input"
        id={field.name}
        data-testid={`${field.name}-input`}
        {...field}
        {...props}
      />
    </>
  );
};

CheckboxInput.propTypes = {
  field: PropTypes.object
};

export default CheckboxInput;
