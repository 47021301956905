import shortid from "shortid";

const uid = () => {
  let sid = sessionStorage.getItem("spark-sid");
  if (!sid) {
    sid = shortid.generate();
    sessionStorage.setItem("spark-sid", sid);
  }
  return sid;
};

export default uid;
