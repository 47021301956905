import en from "./locales/en.json";
import enGB from "./locales/en-GB.json";
import es from "./locales/es.json";
import frCA from "./locales/fr-CA.json";
import frFR from "./locales/fr-FR.json";

const translations = {
  en: {
    translation: en
  },
  "en-GB": {
    translation: enGB
  },
  es: {
    translation: es
  },
  "fr-CA": {
    translation: frCA
  },
  fr: {
    translation: frFR
  }
};

export default translations;
