export const warnSlug = value => {
  const exclusions = value
    .toLowerCase()
    .match(/^(www|https|http)|(com|net|org|20[0-9]{2})$|test/);
  // starts with www, http, or https OR
  // ends with com, net, org, or 20 followed by two more digits OR
  // contains the word test

  if (exclusions && exclusions[0]) {
    return {
      key: "forms.warnings.slug_recommended_exclusion",
      placeholders: {
        text: exclusions[0]
      }
    };
  }

  return {};
};
