import React from "react";
import PropTypes from "prop-types";
import { useStateValue } from "../../hooks/state";
import { randomItem } from "../../lib/utils";

import ImageSidePanel from "./ImageSidePanel";
import panelsConfig from "../../data/imageSidePanels.json";

const getRandomImageKey = category =>
  randomItem(Object.keys(panelsConfig[category]));

const ConnectedImageSidePanel = ({ category, initialImage, random }) => {
  const [
    {
      imageSidePanel: { image = initialImage }
    }
  ] = useStateValue();
  const imageKey = random ? getRandomImageKey(category) : image;
  const panelProps = panelsConfig[category][imageKey];
  return <ImageSidePanel {...panelProps} />;
};

ConnectedImageSidePanel.propTypes = {
  category: PropTypes.string.isRequired,
  initialImage: PropTypes.string,
  random: PropTypes.bool
};

export default ConnectedImageSidePanel;
