const userDataReducer = (state, action) => {
  switch (action.type) {
    case "set-data":
      return {
        ...state,
        ...action.data
      };
    case "nbuild-load-data":
      return {
        ...state,
        email: action.data.signup.email,
        firstName: action.data.signup.first_name,
        lastName: action.data.signup.last_name
      };
    default:
      return state;
  }
};

export default userDataReducer;
