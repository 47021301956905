import Autosuggest from "react-autosuggest";
import cn from "classnames";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const AutosuggestInput = ({
  alwaysRenderSuggestions,
  field,
  handleSuggestionSelected,
  onBlur,
  options,
  searchPlaceholder,
  setFieldValue,
  selectInput,
  showError,
  type
}) => {
  const [focused, setFocused] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState({});
  const [suggestions, setSuggestions] = useState(options || []);
  const [renderSuggestions, setRenderSuggestions] = useState(
    alwaysRenderSuggestions
  );
  const inputId = `${field.name}-input`;

  const { t } = useTranslation();

  useEffect(() => {
    document.getElementById(inputId).focus();
    return;
  }, [focused]);

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? options
      : options
          .filter(option => option.name.toLowerCase().includes(inputValue))
          .sort((a, b) => sortSuggestions(a, b, inputValue));
  };

  const sortSuggestions = (a, b, inputValue) => {
    return (
      a.name.toLowerCase().indexOf(inputValue) -
      b.name.toLowerCase().indexOf(inputValue)
    );
  };

  const onSuggestionSelected = (e, { suggestionValue }) => {
    const option = options.find(opt => opt.name === suggestionValue);
    const suggestion = option.value || option.name;
    setSelectedOption(option);
    setFieldValue(field.name, suggestion);
    handleSuggestionSelected(suggestion);
  };

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    setSuggestions(getSuggestions(value));
  };

  const renderSuggestion = suggestion => {
    let classNames = cn("list-item d-flex align-items-center", {
      highlighted: suggestion.name === selectedOption.name
    });

    return (
      <div className={classNames}>
        <div className="p-1">{suggestion.name}</div>
      </div>
    );
  };

  const renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const displayNoSuggestions =
      suggestions.length === 0 && focused && searchValue;
    showError(suggestions.length === 0 && searchValue);

    let classNames = cn(
      "gs-autosuggest",
      "menu-options",
      "menu-options--fixed-height",
      "w-100",
      "bg-white",
      {
        "suggestions-wide": children
      }
    );

    return (
      <div className="position-relative">
        <div
          style={{
            position: "absolute",
            zIndex: "100",
            paddingBottom: "0px !important"
          }}
          className={classNames}
          id={containerProps.id}
          key={containerProps.key}
          ref={containerProps.ref}
        >
          {children}
          {displayNoSuggestions && (
            <ul className="p-0 m-0">
              <li className="gs-no-results text-muted bg-white border">
                <div className="p-1">{t("forms.hints.no_matches_found")}</div>
              </li>
            </ul>
          )}
        </div>
      </div>
    );
  };

  const inputProps = {
    id: inputId,
    onBlur: e => {
      onBlur(field.name, e);
      setFocused(false);
      setRenderSuggestions(false || alwaysRenderSuggestions);
      if (selectInput) {
        setSearchValue("");
      }
    },
    onChange: (event, { newValue }) => {
      setSearchValue(newValue);
      setFocused(true);
    },
    onMouseDown: () => setFocused(true),
    onKeyDown: e => {
      if (e.key === "Enter") {
        setRenderSuggestions(true);
      }
    },
    placeholder: t(searchPlaceholder, searchPlaceholder),
    type: "search",
    value: searchValue,
    "data-testid": type === "autosuggest" ? inputId : "",
    autoComplete: "off"
  };

  const theme = {
    input: "form-control search",
    containerOpen: "suggestions-container-open",
    suggestionsContainer: "suggestions-container",
    suggestion: cn("suggestion border-bottom"),
    suggestionHighlighted: "focused",
    suggestionsList: "p-0 m-0"
  };

  return (
    <Autosuggest
      alwaysRenderSuggestions={renderSuggestions}
      data-testid={`${field.name}-input`}
      getSuggestionValue={suggestion => suggestion.name}
      inputProps={inputProps}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsClearRequested={() => {}}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      suggestions={suggestions}
      theme={theme}
      id={field.name}
      ref={autosuggest => {
        if (autosuggest !== null) {
          if (focused || selectInput) {
            autosuggest.input.focus();
          }
        }
      }}
    />
  );
};

AutosuggestInput.propTypes = {
  alwaysRenderSuggestions: PropTypes.bool,
  showError: PropTypes.func,
  field: PropTypes.object.isRequired,
  handleSuggestionSelected: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchPlaceholder: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  selectInput: PropTypes.bool
};

AutosuggestInput.defaultProps = {
  alwaysRenderSuggestions: false,
  showError: () => {},
  handleSuggestionSelected: () => {},
  onBlur: () => {},
  options: [],
  searchPlaceholder: "forms.placeholders.search",
  setFieldValue: () => {},
  selectInput: false
};

export default AutosuggestInput;
