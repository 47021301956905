import PropTypes from "prop-types";
import React from "react";

import TextInput from "./TextInput";

const SlugInput = ({ field, ...props }) => (
  <div className="input-group">
    <div className="input-group-prepend">
      <span className="input-group-text bg-white">
        <span className="text-success d-inline-flex align-items-center">
          <svg className="mr-1" width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true"><path d="M12 2.5c-3.305 0-6 2.695-6 6V10h-.75A2.258 2.258 0 003 12.25v7.5A2.258 2.258 0 005.25 22h13.5A2.258 2.258 0 0021 19.75v-7.5A2.258 2.258 0 0018.75 10H18V8.5c0-3.305-2.695-6-6-6zM12 4c2.496 0 4.5 2.004 4.5 4.5V10h-9V8.5C7.5 6.004 9.504 4 12 4zm-6.75 7.5h13.5a.74.74 0 01.75.75v7.5a.74.74 0 01-.75.75H5.25a.74.74 0 01-.75-.75v-7.5a.74.74 0 01.75-.75z" fill="currentColor"></path></svg>
          https://
        </span>
      </span>
    </div>
    <TextInput type="text" field={field} {...props} />
    <div className="input-group-append">
      <span className="input-group-text">
        <span className="text-muted">.nationbuilder.com</span>
      </span>
    </div>
  </div>
);

SlugInput.propTypes = {
  field: PropTypes.object
};

export default SlugInput;
