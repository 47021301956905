import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import Button from "./Button";
import FormGroup from "./FormGroup";
import FormRow from "./FormRow";
import { useFlow } from "../hooks/flow";

const SubmitButton = ({ disabled }) => {
  const { isLastStep } = useFlow();

  const { t } = useTranslation();

  return (
    <FormRow>
      <FormGroup>
        <Button type="submit" disabled={disabled} data-testid="submit-button">
          {isLastStep
            ? t("forms.actions.start_your_14_day_free_trial")
            : t("forms.actions.save_and_continue")}
        </Button>
      </FormGroup>
    </FormRow>
  );
};

SubmitButton.defaultProps = {
  disabled: false
};

SubmitButton.propTypes = {
  disabled: PropTypes.bool
};

export default SubmitButton;
