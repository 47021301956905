import React, { useRef } from "react";
import ReactTooltip from "react-tooltip";

const InfoBadge = ({ id, text, tooltipText }) => {
  const node = useRef();
  return (
    <>
      <span
        ref={node}
        className="badge badge-pill badge--info ml-1"
        data-tip="true"
        tabIndex="0"
        onFocus={e => ReactTooltip.show(node.current)}
        onBlur={e => ReactTooltip.hide(node.current)}
        aria-describedby={id}
        data-for={id}
      >
        {text}
      </span>
      <ReactTooltip
        id={id}
        className="tooltip px-2 py-1"
        place="top"
        type="light"
        effect="solid"
        multiline={true}
      >
        {tooltipText}
        <style global="true" jsx="true">{`
          .tooltip {
            position: fixed;
          }
          .tooltip.place-top:before {
            border-top-color: hsl(195, 5%, 83%);
            border-top-width: 8px;
            border-top-style: solid;
          }
          .tooltip.place-bottom:before {
            border-bottom-color: hsl(195, 5%, 83%);
            border-bottom-width: 8px;
            border-bottom-style: solid;
            top: -8px;
          }
          .tooltip:after {
            border-top-color: hsl(0, 0%, 100%);
          }
        `}</style>
      </ReactTooltip>
    </>
  );
};

InfoBadge.defaultProps = {
  id: "tooltip",
  text: "?",
  tooltipText: ""
};

export default InfoBadge;
