import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

export const ButtonRadioInput = ({
  active,
  disabled,
  label,
  onMouseEnter,
  onMouseLeave,
  onClick,
  value
}) => {
  const handleHover = () => onMouseEnter && onMouseEnter(value);
  const handleHoverExit = () => onMouseLeave && onMouseLeave(value);
  return (
    <button
      className={cn("btn btn-radio", { active })}
      disabled={disabled}
      id={value}
      name={value}
      onClick={onClick}
      onFocus={handleHover}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverExit}
      type="submit"
      data-testid={`btn-radio-${value}`}
    >
      {label}
    </button>
  );
};

ButtonRadioInput.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string
};

export default ButtonRadioInput;
