import React from "react";

import BackButton from "./BackButton";
import Logo from "./Logo";
import ProgressIndicator from "./ProgressIndicator";

const Header = () => (
  <header className="fixed-top">
    <ProgressIndicator />
    <div className="container-fluid mt-3 px-3">
      <div className="row align-center justify-content-between">
        <nav className="col-auto">
          <BackButton />
        </nav>
        <div className="col-auto">
          <Logo />
        </div>
      </div>
    </div>
  </header>
);

export default Header;
