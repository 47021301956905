import React from "react";

import { useFlow } from "../hooks/flow";

const ProgressIndicator = () => {
  const { percentComplete } = useFlow();

  return (
    <div
      className="progress rounded-0"
      style={{ backgroundColor: "rgba(0,0,0,.1)", height: "4px" }}
    >
      <div
        className="progress-bar bg-success"
        role="progressbar"
        style={{ width: `${percentComplete}%` }}
        aria-valuenow={percentComplete}
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  );
};

export default ProgressIndicator;
