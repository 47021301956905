import steps from "../components/steps";
import { useStateValue } from "./state";

const flows = {
  default: ["language", "country", "useCase", "settings", "account", "billing"]
};

export const useFlow = () => {
  const [{ flow }, dispatch] = useStateValue();

  const { flowType, currentStepName: stepName } = flow;
  const flowSteps = Array.isArray(flowType) ? flowType : flows[flowType];
  const currentStepName = stepName || flowSteps[0];
  const currentIndex = flowSteps.indexOf(currentStepName);

  const getNextStepName = () => {
    return flowSteps[currentIndex + 1];
  };

  const getPrevStepName = () => {
    const previousIndex = Math.max(currentIndex - 1, 0);
    return flowSteps[previousIndex];
  };

  const gotoNextStep = options => {
    const stepName = getNextStepName();

    if (stepName) {
      dispatch({ type: "flow-set-current", stepName });
    } else {
      dispatch({ type: "flow-finished", finished: true });
    }
  };

  const gotoPrevStep = () => {
    const stepName = getPrevStepName();
    dispatch({ type: "flow-set-current", stepName });
  };

  return {
    steps: flowSteps.map(stepName => steps[stepName]),
    currentStep: steps[currentStepName],
    currentStepIndex: currentIndex,
    gotoNextStep,
    gotoPrevStep,
    finished: flow.finished,
    isFirstStep: currentIndex === 0,
    isLastStep: !getNextStepName(),
    percentComplete: flow.finished
      ? 100
      : Math.floor((100 / flowSteps.length) * currentIndex)
  };
};
