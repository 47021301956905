import React from "react";
import PropTypes from "prop-types";
import Note from "./Note";
import { useTranslation } from "react-i18next";

const SuggestionNote = props => {
  const { t } = useTranslation();

  const note = (
    <>
      <em>{t("components.suggestion_note.a_few_examples")} </em>
      <b>{props.suggestions.join(", ")}</b>.
    </>
  );

  return <Note animated={props.animated}>{note}</Note>;
};

SuggestionNote.propTypes = {
  suggestions: PropTypes.array,
  animated: PropTypes.bool
};

SuggestionNote.defaultProps = {
  animated: false
}

export default SuggestionNote;
