import countryNames from "i18n-iso-countries";
import { mapEach } from "../utils";
import currencies from "./data/currencies.json";
import { countries } from "countries-list";
import states from "./data/states.json";
import timezones from "./data/timezones.json";
import { isSupportedCountry } from "libphonenumber-js";

export const allCountryOptions = (language) => {
  const languageCode = language.slice(0, 2).toLowerCase() || "en";
  countryNames.registerLocale(
    require(`i18n-iso-countries/langs/${languageCode}.json`)
  );
  const names = countryNames.getNames(languageCode);

  return Object.keys(names).map((alpha2) => {
    return { name: names[alpha2], value: alpha2 };
  });
};

export const getFirstCurrency = (alpha2) => {
  const country = countries[alpha2];

  if (!country) {
    return null;
  }

  return country.currency.split(",")[0] || null;
};

export const allCurrencyOptions = Object.keys(currencies).map((currency) => {
  return {
    name: currencies[currency].iso_code,
    value: currencies[currency].iso_code,
  };
});

const splitPhoneCodes = (codes) =>
  // If country has more than 1 code, it may be an array or a comma
  // separated string.
  Array.isArray(codes) ? codes : codes.split(",");

export const allPhoneCodeOptions = () => {
  const uniqueCodes = [];

  Object.keys(countries).forEach((key) => {
    const country = countries[key];
    splitPhoneCodes(country.phone).forEach((code) => {
      if (!uniqueCodes.includes(code)) {
        uniqueCodes.push(code);
      }
    });
  });

  return uniqueCodes
    .sort((a, b) => a - b)
    .map((code) => {
      return { name: `+${code}`, value: code };
    });
};

export const getFirstPhoneCode = (alpha2) => {
  const country = countries[alpha2];
  return country ? splitPhoneCodes(country.phone)[0] : "";
};

export const getCountryFromPCC = (code) =>
  Object.keys(countries).find(
    (key) => countries[key].phone === code && isSupportedCountry(key)
  );

export const allStateOptions = Object.keys(states).map((name) => {
  return { name, value: states[name] };
});

export const allTimeZoneOptions = mapEach(timezones, (name, info) => {
  return { name: `${name} (GMT${info.offset})`, value: name };
});

export const findTimeZoneById = (id) =>
  Object.keys(timezones).find((tz) => timezones[tz].id === id);

export const findCurrentTimeZone = () => {
  try {
    return findTimeZoneById(Intl.DateTimeFormat().resolvedOptions().timeZone);
  } catch {
    return null;
  }
};
