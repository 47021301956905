import React from "react";
const { REACT_APP_DEBUG_FORMIK } = process.env;

export const DisplayFormikState = props =>
  REACT_APP_DEBUG_FORMIK === "true" ? (
    <div style={{ margin: "1rem 0" }}>
      <h3 style={{ fontFamily: "monospace" }}>Formik Debugger</h3>
      <pre
        style={{
          background: "#f6f8fa",
          fontSize: ".65rem",
          padding: ".5rem"
        }}
      >
        <strong>props</strong> = {JSON.stringify(props, null, 2)}
      </pre>
    </div>
  ) : null;
