export const readCookie = name => {
  const cookies = document.cookie.split(";");
  const values = {};
  cookies.forEach(cookie => {
    const [cookieName, cookieVal] = cookie.split("=");
    values[cookieName.trim()] = cookieVal;
  });
  return values[name];
};

// Returns a new object that has the same keys as
// `obj`.  If the key value in `obj` is `undefined`, the returned
// object will have that key set to `val`.
// defaultAll({
//   firstName: 'James',
//   lastName: undefined
// }) => { firstName: 'James', lastName: "" }
export const defaultAll = (obj, val = "") => {
  let defaultValues = {};
  Object.keys(obj).forEach(
    k => (defaultValues[k] = typeof obj[k] === "undefined" ? val : obj[k])
  );
  return defaultValues;
};

export const mapEach = (obj, fn) => Object.keys(obj).map(k => fn(k, obj[k]));

export const redirect = (url, delay = 0) => {
  if (url) {
    if (delay) {
      setTimeout(() => {
        window.location = url;
      }, delay);
    } else {
      window.location = url;
    }
  }
};

export const randomItem = arr => arr[Math.floor(Math.random() * arr.length)];

export const queryString = params => {
  const pairs = mapEach(params, (k, v) => {
    return `${k}=${v ? encodeURIComponent(v) : ""}`;
  });
  return pairs.join("&");
};
