import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { allCountryOptions } from "../../lib/globalization";
import { defaultAll } from "../../lib/utils";
import FormInput from "../FormInput";
import FormRow from "../FormRow";
import StaticSidePanel from "../side-panels/StaticSidePanel";
import { useStateValue } from "../../hooks/state";

const Country = ({ fullWidth, saveStep, testId, title }) => {
  const [{ userData: { country } = {} }] = useStateValue();
  const [error, setError] = useState(false);
  const { i18n, t } = useTranslation();

  return (
    <main
      data-testid={testId}
      className="row bg-white position-absolute w-100 min-vh-100"
    >
      <div className="col-12 col-md align-self-center ">
        <div
          className="mx-auto px-3 py-5"
          style={{ maxWidth: fullWidth ? "490px" : "560px" }}
        >
          <h1 className="mt-4 mt-md-5">{title}</h1>
          <form onSubmit={e => e.preventDefault()}>
            <FormRow>
              <FormInput
                groupClasses={`${error ? "error" : ""}`}
                field={{
                  name: "country",
                  value: defaultAll({ country }).country
                }}
                name="country"
                type="autosuggest"
                options={allCountryOptions(i18n.language)}
                labelText={t("forms.placeholders.search_countries")}
                labelHidden={true}
                searchPlaceholder={t("forms.placeholders.search_countries")}
                showError={errors => setError(errors)}
                handleSuggestionSelected={suggestion =>
                  saveStep({ country: suggestion })
                }
              />
            </FormRow>
          </form>
        </div>
      </div>
      {fullWidth ? null : <StaticSidePanel />}
    </main>
  );
};

Country.propTypes = {
  fullWidth: PropTypes.bool,
  saveStep: PropTypes.func.isRequired
};

export default Country;
