import React from "react";
import PropTypes from "prop-types";
import SidePanelWrapper from "./SidePanelWrapper";
const StaticSidePanel = ({ backgroundColor, showOnMobile, image, imageCopy, children }) => (
  <SidePanelWrapper backgroundColor={backgroundColor} showOnMobile={showOnMobile}>
    {image || imageCopy ? (
      <>
        <div className="image-holder" style={{}} />
        <p className="h3 mt-3 text-white image-copy">{imageCopy}</p>
      </>
    ) : (
      children
    )}
  </SidePanelWrapper>
);

StaticSidePanel.propTypes = {
  backgroundColor: PropTypes.string,
  image: PropTypes.string,
  imageCopy: PropTypes.string,
  showOnMobile: PropTypes.bool
};

StaticSidePanel.defaultProps = {
  backgroundColor: "#bfbfbf",
  image: "",
  imageCopy: "",
  showOnMobile: false
};

export default StaticSidePanel;
