import React from "react";

const Logo = () => (
  <div className="bg-white rounded-circle" style={{ padding: "3px" }}>
    <img
      className="mw-100 "
      src="./images/nationbuilder-mark-black.svg"
      alt="NationBuilder"
      width="auto"
      height="36"
    />
  </div>
);

export default Logo;
