import { debounce } from "debounce";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  allCurrencyOptions,
  allStateOptions,
  allTimeZoneOptions,
  getFirstCurrency
} from "../../lib/globalization";
import { defaultAll } from "../../lib/utils";
import { DisplayFormikState } from "../../helpers";
import FormInput from "../FormInput";
import FormRow from "../FormRow";
import ConnectedImageSidePanel from "../side-panels/ConnectedImageSidePanel";
import SubmitButton from "../SubmitButton";
import {
  validateCurrency,
  validateOrganizationName,
  validateSlugChangeAndBlur,
  validateSlugSubmission,
  validateState,
  validateTimeZone,
  validateZip
} from "../../validate";
import { warnSlug } from "../../warn";
import { useStateValue } from "../../hooks/state";

import useCaseSuggestions from "../../data/suggestions.json";

const Settings = ({ fullWidth, networkErrors, saveStep, testId, title }) => {
  const [
    {
      userData: {
        country,
        currency,
        organizationName,
        slug,
        state,
        taxExempt,
        timeZone,
        useCase,
        zip
      } = {},
      network: { sid }
    },
    dispatch
  ] = useStateValue();

  const { t, i18n } = useTranslation();

  const SettingsForm = props => {
    const {
      errors,
      handleBlur,
      handleChange,
      isSubmitting,
      isValidating,
      setErrors,
      setFieldTouched,
      setFieldValue,
      touched,
      validateField,
      values
    } = props;

    const [suggestions, setSuggestions] = useState();
    const [slugWarning, setSlugWarning] = useState(null);

    const debouncedValidateField = useCallback(
      // Passing true to debounce ensures we run validation on the leading
      // edge and clear any existing errors so they don't flash onscreen.
      debounce(validateField, 10, true),
      [validateField]
    );

    useEffect(() => {
      if (networkErrors) {
        setErrors(networkErrors);
        setFieldTouched(Object.keys(networkErrors));
      }
      return () => debouncedValidateField.clear();
    }, []);

    useEffect(() => {
      if (slug || (touched.slug && !errors.slug)) {
        const { key, placeholders } = warnSlug(values.slug);
        const warning = key ? t(key, placeholders) : null;
        setSlugWarning(warning);
        if (warning) {
          setSuggestions(useCaseSuggestions[useCase]);
        }
      }
      if (values.slug && errors.slug) {
        setSuggestions(useCaseSuggestions[useCase]);
      }
    }, [values, errors, touched]);

    const onBlur = async (fieldName, e) => {
      await handleBlur(e);
      validateField(fieldName);
    };

    const onChange = async (fieldName, e) => {
      await handleChange(e);
      debouncedValidateField(fieldName);
    };

    return (
      <Form>
        <fieldset>
          <legend className="sr-only">{t("forms.legend.setup_account")}</legend>
          <FormRow>
            <Field
              component={FormInput}
              type="text"
              name="organizationName"
              labelText={t("forms.labels.organization_name")}
              autoCorrect="off"
              autoCapitalize="words"
              autoFocus={true}
              disabled={isSubmitting}
              validate={validateOrganizationName}
              onBlur={e => onBlur("organizationName", e)}
              onChange={e => onChange("organizationName", e)}
            />
          </FormRow>
          <FormRow>
            <Field
              component={FormInput}
              type="slug"
              name="slug"
              placeholder={t("forms.placeholders.slug")}
              autoComplete="off"
              autoCorrect="off"
              disabled={isSubmitting}
              labelText={`${t("forms.labels.slug")} <span class="sr-only">(${t("forms.hints.this_cannot_be_changed_you_can_add_your_own_custom_domain_later")})</span>`}
              hintText={t(
                "forms.hints.this_cannot_be_changed_you_can_add_your_own_custom_domain_later"
              )}
              infoText={t("forms.tooltips.slug")}
              suggestions={suggestions}
              groupClasses="nb-url"
              onBlur={e => onBlur("slug", e)}
              onChange={e => onChange("slug", e)}
              validate={validateSlugChangeAndBlur}
              warning={slugWarning}
            />
          </FormRow>
          {country === "US" ? (
            <FormRow>
              <Field
                component={FormInput}
                type="select"
                name="state"
                labelText={t("forms.labels.state")}
                disabled={isSubmitting}
                options={allStateOptions}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                validate={validateState}
                onChange={e => onChange("state", e)}
                onBlur={e => onBlur("state", e)}
                placeholder={t("forms.placeholders.select_a_state")}
                groupClasses="col-12 col-sm-6"
              />
              <Field
                component={FormInput}
                type="text"
                name="zip"
                labelText={t("forms.labels.zip_code")}
                disabled={isSubmitting}
                validate={validateZip}
                onChange={e => onChange("zip", e)}
                onBlur={e => onBlur("zip", e)}
                maxLength="10"
              />
            </FormRow>
          ) : null}
          <FormRow>
            <Field
              component={FormInput}
              type="select"
              name="currency"
              labelText={t("forms.labels.currency")}
              disabled={isSubmitting}
              options={allCurrencyOptions}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              validate={validateCurrency}
              onChange={e => onChange("currency", e)}
              onBlur={e => onBlur("currency", e)}
              placeholder={t("forms.placeholders.select_a_currency")}
              groupClasses="col-12 col-sm-6"
            />
            <Field
              component={FormInput}
              type="select"
              name="timeZone"
              labelText={t("forms.labels.default_time_zone")}
              disabled={isSubmitting}
              options={allTimeZoneOptions}
              placeholder={t("forms.placeholders.select_a_time_zone")}
              groupClasses="col-12 col-sm-6"
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              validate={validateTimeZone}
              onChange={e => onChange("timeZone", e)}
              onBlur={e => onBlur("timeZone", e)}
            />
          </FormRow>
          {country === "US" ? (
            <FormRow>
              <Field
                component={FormInput}
                type="checkbox"
                name="taxExempt"
                labelText={
                  t("forms.labels.tax_exempt_status") +
                  " &nbsp; <a href='https://support.nationbuilder.com/en/articles/3185363-request-tax-exemption' target='_blank' rel='noopener noreferrer'>" +
                  t("forms.labels.learn_more") +
                  "</a>"
                }
                disabled={isSubmitting}
                groupClasses="form-checkbox"
                checked={values.taxExempt}
              />
            </FormRow>
          ) : null}
          <SubmitButton disabled={isSubmitting || isValidating} />
          <DisplayFormikState {...props} />
        </fieldset>
      </Form>
    );
  };

  return (
    <div
      data-testid={testId}
      className="row bg-white position-absolute w-100 min-vh-100"
    >
      <main className="col-12 col-md-6 align-self-center">
        {/* maxWidth below is 490px if no side panel */}
        <div className="mx-auto px-3 py-5" style={{ maxWidth: "560px" }}>
          <h1 className="mt-4 mt-md-5">{title}</h1>
          <Formik
            initialValues={defaultAll({
              currency: currency || getFirstCurrency(country) || "USD",
              organizationName,
              slug,
              state,
              taxExempt,
              timeZone,
              zip
            })}
            onSubmit={(values, actions) => {
              saveStep(values);
            }}
            validate={values =>
              validateSlugSubmission(values, sid, i18n.language, dispatch)
            }
            validateOnBlur={false}
            validateOnChange={false}
            component={SettingsForm}
          />
        </div>
      </main>
      {fullWidth ? null : <ConnectedImageSidePanel category="useCase" />}
    </div>
  );
};

Settings.propTypes = {
  fullWidth: PropTypes.bool,
  networkErrors: PropTypes.object,
  saveStep: PropTypes.func.isRequired
};

export default Settings;
