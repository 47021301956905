const pricingInfoReducer = (state, action) => {
  switch (action.type) {
    case "set-pricing-data":
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default pricingInfoReducer;
