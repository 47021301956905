import cn from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import AutosuggestInput from "./AutosuggestInput";

const SelectInput = ({
  field,
  disabled,
  onBlur,
  onChange,
  options,
  placeholder,
  setFieldValue,
  setFieldTouched,
  type,
  ...props
}) => {
  const node = useRef();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  const handleClick = e => {
    if (
      elementIsSelect(e.target) &&
      node.current.parentElement.parentElement.contains(e.target)
    ) {
      return;
    }
    if (node.current && !node.current.contains(e.target)) {
      closeAutosuggest();
    }
  };

  const elementIsSelect = elem => {
    return (
      elem.className === "select2-chosen" ||
      (elem.nodeName === "B" &&
        elem.parentElement.nodeName === "SPAN" &&
        elem.parentElement.className === "select2-arrow")
    );
  };

  const isSelectContainer = elem => {
    return (
      typeof elem.classList != "undefined" &&
      elem.classList.contains("select2-container")
    );
  };

  const handleKeyDown = e => {
    if (
      e.target.type !== "search" &&
      !elementIsSelect(e.target) &&
      !isSelectContainer(e.target)
    ) {
      return;
    }
    if (open && e.key === "Escape") {
      closeAutosuggest();
    } else if (e.key === "Enter") {
      e.preventDefault();
    } else if (open && e.key === "Tab") {
      closeAutosuggest();
    }
  };

  const handleSelectKeyDown = e => {
    const isTargetContainer = e.target.id === `${field.name}-container`;
    if (
      isTargetContainer &&
      (e.key === "Enter" ||
        e.key === "Spacebar" ||
        e.key === " " ||
        e.key === "ArrowDown")
    ) {
      if (open) {
        closeAutosuggest();
      } else {
        setOpen(true);
        // refocus search input
        document.getElementById(`${field.name}-input`).click();
      }
    }
  };

  const handleSelectClick = e => {
    if (elementIsSelect(e.target)) {
      if (open) {
        closeAutosuggest();
      } else {
        setOpen(true);
        // refocus search input
        document.getElementById(`${field.name}-input`).click();
      }
    } else {
      setOpen(true);
    }
  };

  const closeAutosuggest = () => {
    if (open) {
      setFieldTouched(field.name);
      setOpen(false);
    }
  };

  const handleSuggestionSelected = () => {
    onChange();
    closeAutosuggest();
  };

  const fieldOption = options.find(option => {
    if (option.value) {
      return option.value === field.value;
    } else {
      return option.name === field.value;
    }
  });

  return (
    <div
      className="dropdown"
      style={disabled ? { cursor: "not-allowed" } : null}
    >
      <div
        className={cn("select2-container", { "select2-dropdown-open": open })}
        id={`${field.name}-container`}
        onClick={e => handleSelectClick(e)}
        onKeyDown={handleSelectKeyDown}
        tabIndex="0"
        style={disabled ? { pointerEvents: "none" } : null}
      >
        <span
          className={cn("select2-choice", {
            "select2-default": !fieldOption,
            "text-muted bg-light": disabled
          })}
        >
          <span className="select2-chosen" data-testid={`${field.name}-input`}>
            {fieldOption ? fieldOption.name : t(placeholder, placeholder)}
          </span>
          <abbr className="select2-search-choice-close" />
          <span className="select2-arrow" role="presentation">
            <b role="presentation" />
          </span>
        </span>
      </div>

      <div
        ref={node}
        className={cn("dropdown-menu", open ? "show" : "d-none")}
        style={{
          position: "absolute",
          transform: "translate3d(0px, 40px, 0px)",
          top: "0px",
          left: "0px",
          willChange: "transform"
        }}
      >
        <AutosuggestInput
          alwaysRenderSuggestions={true}
          field={field}
          handleSuggestionSelected={handleSuggestionSelected}
          onBlur={onBlur}
          options={options}
          setFieldValue={setFieldValue}
          type={type}
          selectInput={true}
        />
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  disabled: PropTypes.bool,
  field: PropTypes.object.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired
};

SelectInput.defaultProps = {
  disabled: false,
  onBlur: () => {},
  onChange: () => {},
  options: [],
  placeholder: "forms.placeholders.select",
  setFieldTouched: () => {},
  setFieldValue: () => {}
};

export default SelectInput;
