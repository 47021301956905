import { hot } from "react-hot-loader/root";
import React, { useReducer } from "react";
import { BrowserRouter, Route } from "react-router-dom";

import "./lib/i18n";
import "./styles.css";

import {
  handleAPIFailure,
  handleCreationResponse,
  handleNbuildDataLoaded
} from "./lib/responseHandlers";
import uid from "./lib/uid";
import { findCurrentTimeZone } from "./lib/globalization";
import Header from "./components/Header";
import reducer from "./reducers";
import { StateProvider } from "./hooks/state";
import StepManager from "./components/steps/StepManager";
import { submitNationWithTimeout } from "./api";
import useNbuildData from "./hooks/nbuild";

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.com',
  service: 'getting-started',
  env: process.env.REACT_APP_DATADOG_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0', 
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

export const initialState = {
  flow: {
    flowType: "default",
    finished: false
  },
  imageSidePanel: {},
  nbuild: {},
  network: { errors: null, saving: false, serviceDown: false, sid: uid() },
  pricingInfo: {},
  userData: { timeZone: findCurrentTimeZone(), taxExempt: false }
};

const handleFinish = (state, dispatch) => {
  dispatch({ type: "network-saving", saving: true });

  return submitNationWithTimeout(state, dispatch)
    .then(response => {
      dispatch({ type: "network-saving", saving: false });
      handleCreationResponse(response, state, dispatch);
    })
    .catch(response => {
      handleAPIFailure("NationCreationFailure", response, dispatch);
    });
};

function App({ initialState, onFinish = handleFinish }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  useNbuildData(state.network.sid, data =>
    handleNbuildDataLoaded(data, dispatch)
  );

  return (
    <StateProvider state={state} dispatch={dispatch}>
      <BrowserRouter>
        <div className="focused-view">
          {state.network.serviceDown ? null : <Header />}
          <div className="container-fluid">
            <Route
              path="/"
              exact={true}
              render={({ match, history }) => (
                <StepManager
                  onFinish={onFinish}
                  networkErrors={state.network.errors}
                />
              )}
            />
          </div>
        </div>
      </BrowserRouter>
    </StateProvider>
  );
}

export default hot(App);
