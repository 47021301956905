import cn from "classnames";
import { ErrorMessage } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AutosuggestInput from "../components/inputs/AutosuggestInput";
import FormGroup from "./FormGroup";
import InfoBadge from "./InfoBadge";
import InlineError from "../components/InlineError";
import SuggestionNote from "./SuggestionNote";
import CheckboxInput from "../components/inputs/CheckboxInput";
import SelectInput from "../components/inputs/SelectInput";
import SlugInput from "../components/inputs/SlugInput";
import TextInput from "../components/inputs/TextInput";
import PhoneInput from "./inputs/PhoneInput";

const INPUTS = {
  autosuggest: AutosuggestInput,
  checkbox: CheckboxInput,
  phone: PhoneInput,
  select: SelectInput,
  slug: SlugInput
};

const FormInput = ({
  children,
  field,
  form,
  groupClasses,
  hintText,
  infoText,
  labelText,
  labelHidden,
  type,
  warning,
  suggestions,
  ...props
}) => {
  const InputComponent = INPUTS[type] || TextInput;
  const { t } = useTranslation();
  const [showSuggestions, setShowSuggestions] = useState(false);

  const showFieldError = () => {
    // Validations may be triggered on change, blur, and submit. If the form
    // has been submitted, we should show errors for all fields. If not, we
    // should only show errors for fields with a value.
    if (form.submitCount > 0 || field.value !== "") {
      return !!(form.touched[field.name] && form.errors[field.name]);
    } else {
      return false;
    }
  };

  const showError = showFieldError();

  const classes = cn("container-fluid form-field-full", groupClasses, {
    "form-select": type === "select",
    "form-input": type !== "select"
  });

  const label = (
    <>
      <label
        className={cn('w-auto', {
          'sr-only': labelHidden
        })}
        htmlFor={field.name}
        dangerouslySetInnerHTML={{ __html: labelText }}
      />
      {infoText && (
        <InfoBadge tooltipText={infoText} id={`${field.name}-desc`} />
      )}
    </>
  );

  useEffect(() => {
    if (showError || warning) {
      setShowSuggestions(true);
    }
  }, [showError, warning]);

  const errorOrWarning = () => {
    if (showError) {
      return (
        <ErrorMessage
          name={field.name}
          render={msg => (
            <InlineError
              msg={t(msg, msg)}
              data-testid={`${field.name}-error`}
            />
          )}
        />
      );
    } else if (warning) {
      return (
        <InlineError msg={warning} data-testid={`${field.name}-warning`} />
      );
    }

    return null;
  };

  const renderSuggestions = () => {
    return showSuggestions && suggestions ? (
      <SuggestionNote suggestions={suggestions} animated={true} />
    ) : null;
  };

  return (
    <FormGroup error={showError} warning={!!warning} classes={classes}>
      {type === "checkbox" ? null : (
        <div className="form-field-label">{label}</div>
      )}
      <div
        className={`form-field-wrap ${
          type === "select" ? "form-field-full" : ""
        }`}
      >
        <div className="form-field-wrap__item">
          <InputComponent
            type={type}
            field={field}
            children={children}
            {...props}
          />
          {type === "checkbox" ? label : null}
          {errorOrWarning()}
          {renderSuggestions()}
        </div>
        {hintText && (
          <div className="form-field-wrap__item">
            <small
              className="form-text text-muted font-weight-normal"
              htmlFor={field.name}
              dangerouslySetInnerHTML={{ __html: hintText }}
            />
          </div>
        )}
      </div>
    </FormGroup>
  );
};

FormInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object,
  infoText: PropTypes.string,
  labelText: PropTypes.string,
  labelHidden: PropTypes.bool,
  type: PropTypes.string,
  warning: PropTypes.string,
  suggestions: PropTypes.array
};

FormInput.defaultProps = {
  form: { touched: {}, errors: {} },
  labelHidden: false
};

export default FormInput;
