const flowReducer = (state, action) => {
  switch (action.type) {
    case "flow-set-current":
      return {
        ...state,
        currentStepName: action.stepName
      };
    case "flow-finished":
      return {
        ...state,
        currentStepName: null,
        finished: action.finished
      };

    case "network-errors-received":
      return {
        ...state,
        currentStepName: action.nextStepName,
        finished: false
      };
    default:
      return state;
  }
};

export default flowReducer;
