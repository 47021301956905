import React from "react";
import PropTypes from "prop-types";

const SidePanelWrapper = ({ backgroundColor, showOnMobile, children }) => (
  <aside
    className={`col-12 col-md-6 side-panel d-md-flex align-top min-vh-100 ${ !showOnMobile ? "d-none" : "" }`}
    style={{ backgroundColor, overflow: "hidden" }}
  >
    <div className="mx-auto px-3 py-5" style={{ maxWidth: "540px" }}>
      {children}
    </div>
  </aside>
);

SidePanelWrapper.propTypes = {
  backgroundColor: PropTypes.string,
  showOnMobile: PropTypes.bool
};

export default SidePanelWrapper;
